const listActions = {};

listActions.updateSourceValue = (dispatch, data) => dispatch({
  type: 'UPDATE_VALUE_ACTION',
  payload: { data },
});

listActions.increaseSourceValue = (dispatch, data) => dispatch({
  type: 'INCREASE_VALUE_ACTION',
  payload: { data },
});

listActions.decreaseSourceValue = (dispatch, data) => dispatch({
  type: 'DECREASE_VALUE_ACTION',
  payload: { data },
});

listActions.updatePlayer = (dispatch, data) => dispatch({
  type: 'UPDATE_PLAYER',
  payload: { data },
});

export default listActions;
