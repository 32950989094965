/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import Sources from './sources';
import listActions from '../redux/listAction';

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch => ({
  updatePlayer: data => listActions.updatePlayer(dispatch, data),
});

class Main extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sources, updatePlayer, listIndex, playerName,
    } = this.props;
    return (
      <div>
        <h1 className="text">
          <input
            className="input2"
            onChange={(e) => {
              // call redux action
              updatePlayer({
                newName: e.target.value,
                listIndex,
              });
            }}
            value={playerName}
          />
        </h1>
        <div className="">
          {
            sources.map((source, index) => (
              <Sources
                text={source.text}
                value={source.valueText}
                opacity={source.opacity}
                listIndex={listIndex}
                sourceIndex={index}
                image={source.image}
              />
            ))
          }
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Main);
