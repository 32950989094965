import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Main from './components/main';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({});


class App extends Component {
  render() {
    const { listReducer } = this.props;
    return (
      <div className="App">
        <div className="myCol-20">
          <h1 className="App-title">Seven Wonders Battle!</h1>
        </div>
        <div className="myCol-80">
          {
          listReducer.map((list, index) => (
            <div className="myCol-50">
              <Main
                playerName={list.playerName}
                sources={list.sources}
                listIndex={index}
              />
            </div>
          ))
        }
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
