/* eslint-disable no-param-reassign,no-case-declarations */
export default (state = {}, action) => {
  const { listIndex, sourceIndex } = (action.payload && action.payload.data) || '';

  switch (action.type) {
    case 'UPDATE_VALUE_ACTION':
      const { newValue } = action.payload.data;
      state[listIndex].sources[sourceIndex].value = parseFloat(newValue);
      return [
        ...state,
      ];
    case 'INCREASE_VALUE_ACTION':
      state[listIndex].sources[sourceIndex].value += 1;
      state[listIndex].sources[sourceIndex].opacity += 0.3;
      if (state[listIndex].sources[sourceIndex].value >= 4) {
        state[listIndex].sources[sourceIndex].value = 4;
        state[listIndex].sources[sourceIndex].opacity = 1.3;
        state[listIndex].sources[sourceIndex].valueText = '∞';
        state[listIndex].sources[sourceIndex].image = state[listIndex].sources[sourceIndex].images[1];
      } else {
        state[listIndex].sources[sourceIndex].image = state[listIndex].sources[sourceIndex].images[0];        state[listIndex].sources[sourceIndex].valueText = 'INF';
        state[listIndex].sources[sourceIndex].valueText = state[listIndex].sources[sourceIndex].value ;
      }

      return [
        ...state,
      ];
    case 'DECREASE_VALUE_ACTION':
      if (state[listIndex].sources[sourceIndex].value <= 0) {
        state[listIndex].sources[sourceIndex].value = 0;
        state[listIndex].sources[sourceIndex].opacity = 0.1;
  
      } else {
        state[listIndex].sources[sourceIndex].value -= 1;
        state[listIndex].sources[sourceIndex].opacity -= 0.3;
        state[listIndex].sources[sourceIndex].image = state[listIndex].sources[sourceIndex].images[0];        state[listIndex].sources[sourceIndex].valueText = 'INF';
      }
      state[listIndex].sources[sourceIndex].valueText = state[listIndex].sources[sourceIndex].value ;
      return [
        ...state,
      ];
    case 'UPDATE_PLAYER':
      const { newName } = action.payload.data;
      state[listIndex].playerName = newName;
      return [
        ...state,
      ];
    default:
      return state;
  }
};
