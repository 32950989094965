import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import listReducer from './listReducer';
import brick from '../assets/brick.png';
import bricks from '../assets/bricks.png';
import stone from '../assets/stone.png';
import stones from '../assets/stones.png';
import paper from '../assets/paper.png';
import papers from '../assets/papers.png';
import glass from '../assets/glass.png';
import glasses from '../assets/glasses.png';
import wood from '../assets/wood.png';
import woods from '../assets/woods.png';

const initState = {
  listReducer: [
    {
      sources: [
        {
          type: 'brick',
          value: 0,
          valueText: '0',
          text: 'Bricks: ',
          image: brick,
          opacity: 0.1,
          images: [brick, bricks],
        },
        {
          type: 'stone',
          value: 0,
          valueText: '0',
          text: 'Stones: ',
          image: stone,
          opacity: 0.1,
          images: [stone, stones],
        },
        {
          type: 'wood',
          value: 0,
          valueText: '0',
          text: 'Wood: ',
          image: wood,
          opacity: 0.1,
          images: [wood, woods],
        },
        {
          type: 'glass',
          value: 0,
          valueText: '0',
          text: 'Glass: ',
          image: glass,
          opacity: 0.1,
          images: [glass, glasses],
        },
        {
          type: 'paper',
          value: 0,
          valueText: '0',
          text: 'Paper: ',
          image: paper,
          opacity: 0.1,
          images: [paper, papers],
        },
      ],
      playerName: 'Businka',
      playerColor: '',
    },
    {
      sources: [
        {
          type: 'brick',
          value: 0,
          valueText: '0',
          text: 'Bricks: ',
          image: brick,
          opacity: 0.1,
          images: [brick, bricks],
        },
        {
          type: 'stone',
          value: 0,
          valueText: '0',
          text: 'Stones: ',
          image: stone,
          opacity: 0.1,
          images: [stone, stones],
        },
        {
          type: 'wood',
          value: 0,
          valueText: '0',
          text: 'Wood: ',
          image: wood,
          opacity: 0.1,
          images: [wood, woods],
        },
        {
          type: 'glass',
          value: 0,
          valueText: '0',
          text: 'Glass: ',
          image: glass,
          opacity: 0.1,
          images: [glass, glasses],
        },
        {
          type: 'paper',
          value: 0,
          valueText: '0',
          text: 'Paper: ',
          image: paper,
          opacity: 0.1,
          images: [paper, papers],
        },
      ],
      playerName: 'Nafanja',
      playerColor: '',
    },
  ],
};

export default function configureStore(initialState = initState) {
  return createStore(
    combineReducers({
      listReducer,
    }),
    initialState, composeWithDevTools(
      applyMiddleware(thunk),
    ),
  );
}
