/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import listActions from '../redux/listAction';

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch => ({
  updateSourceValue: data => listActions.updateSourceValue(dispatch, data),
  increaseSourceValue: data => listActions.increaseSourceValue(dispatch, data),
  decreaseSourceValue: data => listActions.decreaseSourceValue(dispatch, data),
});

class Sources extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      increaseSourceValue, decreaseSourceValue, updateSourceValue, listIndex, sourceIndex,
    } = this.props;
    return (
      <div className="source myRow">
        <div className="myCol-30-x alignC for-image">
          <img
            className="image"
            style={{ opacity: `${this.props.opacity}` }}
            src={this.props.image}
            alt=""
            onClick={() => {
              increaseSourceValue({
                listIndex,
                sourceIndex,
              });
            }}
          />
        </div>
        <div className="myCol-30 alignC"><div className="text">{this.props.text}</div></div>
        <div className="myCol-30 alignC">
          <div
            role="button"
            className="button"
            onClick={() => {
              increaseSourceValue({
                listIndex,
                sourceIndex,
              });
            }}
          >
            ⮝
          </div>
          <input
            className="input text"
            onChange={(e) => {
              updateSourceValue({
                listIndex,
                sourceIndex,
                newValue: e.target.value,
              });
            }}
            value={this.props.value}
          />
          <div
            role="button"
            className="button"
            onClick={() => {
              decreaseSourceValue({
                listIndex,
                sourceIndex,
              });
            }}
          >
            ⮟
          </div>
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Sources);
